import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Typography,
} from '@material-ui/core';
import { Link } from 'gatsby';

import Logo from '../svg/logo';
import MainMenu from './main-menu';
import LegalMenu from './legal-menu';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Colophon from './colophon';

import menuIcon from '../../images/svg/menu-icon.svg';
import closeIcon from '../../images/svg/close-icon.svg';
import squareMLogo from '../../images/svg/square-m-logo.svg';

const useDrawerStyles = makeStyles((theme) => ({
  paper: {
    background: '#161420',
    boxShadow: 'none',
    maxWidth: 960,
    width: '100%',
    [theme.breakpoints.only('md')]: {
      maxWidth: 640,
    },
  },
  header: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
    },
  },
  legalMenu: {
    padding: theme.spacing(8),
    paddingBottom: theme.spacing(10),
    width: 'calc(100% - 4px)',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.violet.light}`,
    flexShrink: 0,
    fontSize: 16,
    marginTop: 'auto',
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
  },
}));

const useBackdropStyles = makeStyles((theme) => ({
  root: (drawer) => ({
    alignItems: 'start',
    backdropFilter: 'blur(10px)',
    backgroundColor: fade(theme.palette.violet.dark, 0.85),
    paddingTop: 50,
    // Account for backdrop logo's position on drawer open/close by adding padding
    // based on drawer's state when rendered (to account for missing scrollbar):
    paddingRight: drawer ? 17 : 0,
    [theme.breakpoints.only('xs')]: {
      paddingTop: 30,
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: 28,
    },
  }),
}));

const useToggleButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    color: 'white',
    fontWeight: 700,
    marginTop: theme.spacing(1),
    textTransform: 'lowercase',
  },
  label: {
    fontSize: 20,
    fontWeight: 700,
    marginRight: 30,
    textTransform: 'lowercase',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface NavigationDrawerProps {
  logoStyles: {
    root: string;
  };
}

const NavigationDrawer: React.FC<NavigationDrawerProps> = (props) => {
  const menuButtonRef = React.createRef<HTMLButtonElement>();

  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = () => {
    if (menuButtonRef.current) menuButtonRef.current.blur();
    setDrawer(!drawer);
  };

  const drawerStyles = useDrawerStyles();
  const backdropStyles = useBackdropStyles(drawer);
  const toggleButtonStyles = useToggleButtonStyles();

  const drawerBackdropProps = {
    classes: {
      root: backdropStyles.root,
    },
    children: (
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Link to="/" onClick={toggleDrawer}>
              <Logo className={props.logoStyles.root} />
            </Link>
          </Grid>
        </Grid>
      </Container>
    ),
  };

  return (
    <div>
      <Button
        className={toggleButtonStyles.root}
        onClick={toggleDrawer}
        ref={menuButtonRef}
      >
        <Typography
          component="span"
          variant="inherit"
          className={toggleButtonStyles.label}
        >
          Menu
        </Typography>
        <img
          src={menuIcon}
          alt=""
          width="30"
          height="13"
          aria-label="open menu"
        />
      </Button>
      <Drawer
        classes={{ paper: drawerStyles.paper }}
        anchor="right"
        open={drawer}
        onClose={toggleDrawer}
        BackdropProps={drawerBackdropProps}
      >
        <Box
          color="neutral.dark"
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Grid className={drawerStyles.header} container justify="flex-end">
            <Grid item>
              <Button
                className={toggleButtonStyles.root}
                onClick={toggleDrawer}
              >
                <Typography
                  component="span"
                  variant="inherit"
                  className={toggleButtonStyles.label}
                >
                  Close
                </Typography>
                <img
                  src={closeIcon}
                  alt=""
                  width="20"
                  height="20"
                  aria-label="close menu"
                />
              </Button>
            </Grid>
          </Grid>
          <MainMenu />
          <LegalMenu className={drawerStyles.legalMenu} />
          <Grid
            className={drawerStyles.footer}
            container
            alignItems="center"
            justify="space-between"
            wrap="nowrap"
          >
            <Colophon />
            <img src={squareMLogo} alt="" className={drawerStyles.logo} />
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};

NavigationDrawer.defaultProps = {
  logoStyles: {
    root: '',
  },
};

export default NavigationDrawer;

import React from 'react';

import { Link } from 'gatsby';

import { Box, Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const footerMenuColumns = {
  Company: [
    {
      to: '/about/',
      label: 'About',
    },
    {
      to: '/contact/',
      label: 'Contact',
    },
    {
      to: '/agencies/',
      label: 'Agencies',
    },
    {
      to: '/careers/',
      label: 'Careers',
    },
  ],
  Services: [
    {
      to: '/how-it-works/',
      label: 'How It Works',
    },
    {
      to: '/use-cases/',
      label: 'Use Cases',
    },
    {
      to: '/pricing/',
      label: 'Pricing',
    },
    {
      to: '/faq/',
      label: 'FAQ',
    },
  ],
  /*
  // Reminder: Update gatsby-plugin-page-creator options
  // in gatsby-config.ts to stop ignoring case studies.
  'Case Studies': [
    {
      to: '/case-study/wesley-clover',
      label: 'Wesley Clover',
    },
    {
      to: '/case-study/march-networks',
      label: 'March Networks',
    },
    {
      to: '/case-study/canopy-growth',
      label: 'Canopy Growth',
    },
  ],
  */
  'Terms & Policy': [
    {
      to: '/terms-of-service/',
      label: 'Terms of Service',
    },
    {
      to: '/terms-of-use/',
      label: 'Terms of Use',
    },
    {
      to: '/privacy-policy/',
      label: 'Privacy Policy',
    },
  ],
};

const useColumnHeadingStyles = makeStyles({
  root: {
    marginBottom: '1.5em',
    textTransform: 'lowercase',
  },
});

const useListStyles = makeStyles({
  root: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      '&:nth-child(n+2)': {
        marginTop: '.25em',
      },
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover, &:focus': {
          color: 'white',
        },
      },
    },
  },
});

const FooterMenu: React.FC = () => {
  const columnHeadingStyles = useColumnHeadingStyles();
  const listStyles = useListStyles();

  return (
    <nav>
      <Grid container spacing={7}>
        {Object.entries(footerMenuColumns).map(
          ([columnHeading, columnItems], index) => (
            <Grid item key={index}>
              <Box fontFamily="Visby CF" className={columnHeadingStyles.root}>
                <Typography variant="inherit">
                  <strong>{columnHeading}</strong>
                </Typography>
              </Box>
              <ul className={listStyles.root}>
                {columnItems.map((item, index) => (
                  <li key={index}>
                    <Link to={item.to}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </Grid>
          )
        )}
      </Grid>
    </nav>
  );
};

export default FooterMenu;

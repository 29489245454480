import React from 'react';
import { Link } from 'gatsby';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const legalMenuItems = [
  {
    to: '/terms-of-service/',
    label: 'Terms of Service',
  },
  {
    to: '/terms-of-use/',
    label: 'Terms of Use',
  },
  {
    to: '/privacy-policy/',
    label: 'Privacy Policy',
  },
];

const useLegalMenuItemStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '25px !important',
    paddingRight: '25px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover, &:focus': {
        color: 'white',
      },
      '& span': {
        fontFamily: 'Visby CF, sans-serif',
        fontSize: 16,
        fontWeight: 600,
        textTransform: 'lowercase',
      },
    },
  },
}));

interface LegalMenuProps {
  className?: string;
}

const LegalMenu: React.FC<LegalMenuProps> = (props) => {
  const legalMenuItemStyles = useLegalMenuItemStyles();

  return (
    <nav className={props.className}>
      <Grid container spacing={1} justify="center">
        {legalMenuItems.map((item, index) => (
          <Grid className={legalMenuItemStyles.root} item key={index}>
            <Link to={item.to}>
              <Typography component="span" variant="inherit">
                {item.label}
              </Typography>
            </Link>
          </Grid>
        ))}
      </Grid>
    </nav>
  );
};

export default LegalMenu;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useVerticalPaddingStyles = makeStyles((theme) => ({
  root: (props) => ({
    paddingTop: props.lg ? 240 : 120,
    paddingBottom: props.lg ? 240 : 120,
    [theme.breakpoints.down('sm')]: {
      paddingTop: props.lg ? 120 : 60,
      paddingBottom: props.lg ? 120 : 60,
    },
  }),
}));

interface VerticalPaddingProps {
  lg?: any;
  className?: string;
  children?: React.ReactNode;
}

const VerticalPadding: React.FC<VerticalPaddingProps> = (props) => {
  const verticalPaddingStyles = useVerticalPaddingStyles(props);

  return (
    <div className={`${verticalPaddingStyles.root} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default VerticalPadding;

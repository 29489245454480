import React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from '../../lib/theme';
import Logo from '../svg/logo';
import FooterMenu from './footer-menu';
import Colophon from './colophon';

import squareMLogo from '../../images/svg/square-m-logo.svg';

const useBoxStyles = makeStyles({
  root: {
    fontSize: 16,
    overflow: 'hidden',
    paddingTop: theme.spacing(10),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
});

const useLogoStyles = makeStyles({
  root: {
    display: 'block',
    height: 'auto',
    marginLeft: 'auto',
    maxWidth: 540,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 33,
      width: 'auto',
    },
  },
});

const useColophonStyles = makeStyles({
  root: {
    marginTop: theme.spacing(8),
    paddingLeft: 120,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      borderTop: `1px solid ${theme.palette.violet.light}`,
    },
  },
});

const useSquareMLogoStyles = makeStyles({
  root: {
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: 100,
    },
  },
});

const Footer: React.FC = () => {
  const boxStyles = useBoxStyles();
  const logoStyles = useLogoStyles();
  const colophonStyles = useColophonStyles();
  const squareMLogoStyles = useSquareMLogoStyles();

  return (
    <Box className={boxStyles.root} bgcolor="#161420" color="neutral.dark">
      <Hidden smDown>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8} xl={6}>
              <FooterMenu />
            </Grid>
            <Hidden mdDown>
              <Grid item lg={4} xl={6}>
                <Link to="/">
                  <Logo
                    className={logoStyles.root}
                    fill={theme.palette.violet.light}
                  />
                </Link>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Hidden>
      <Grid
        className={colophonStyles.root}
        container
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
      >
        <Colophon />
        <img
          src={squareMLogo}
          alt=""
          className={squareMLogoStyles.root}
          width="130"
          height="130"
        />
      </Grid>
    </Box>
  );
};

export default Footer;

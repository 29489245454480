import React from 'react';

import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useLightModestyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutral.main,
    color: theme.palette.violet.main,
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      color: theme.palette.primary.dark,
    },
    '& a': {
      color: theme.palette.primary.dark,
    },
  },
}));

interface LightModeProps {
  children: React.ReactNode;
}

const LightMode: React.FC<LightModeProps> = ({ children }) => {
  const lightModeStyles = useLightModestyles();

  return <Box className={lightModeStyles.root}>{children}</Box>;
};

export default LightMode;

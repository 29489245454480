import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const mainMenuItems = [
  {
    to: '/how-it-works/',
    label: 'How It Works',
  },
  {
    to: '/use-cases/',
    label: 'Use Cases',
  },
  {
    to: '/pricing/',
    label: 'Pricing',
  },
  {
    to: '/agencies/',
    label: 'Agencies',
  },
  {
    to: '/faq/',
    label: 'FAQ',
  },
  {
    to: '/about/',
    label: 'About',
  },
  {
    to: '/contact/',
    label: 'Contact',
  },
];

interface ListItemLink {
  className?: string;
  activeClassName?: string;
  to: string;
}

const ListItemLink: React.FC<ListItemLink> = (props) => (
  <ListItem button component={GatsbyLink} {...props} />
);

const useListItemStyles = makeStyles((theme) => ({
  link: {
    width: 200,
    margin: 'auto',
    '&--active': {
      '& [class*="MuiListItemText"]': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    '&:hover, &:focus': {
      backgroundColor: 'transparent !important',
      '& [class*="MuiListItemText"]': {
        '& span': {
          '&::after': {
            content: "' _'",
            position: 'absolute',
            marginLeft: 4,
          },
        },
      },
    },
    '@media (max-height: 640px)': {
      maxHeight: 48,
    },
  },
  text: {
    '& span': {
      color: 'white',
      fontFamily: 'Visby CF, sans-serif',
      fontSize: 20,
      fontWeight: 700,
      marginBottom: 0,
      textAlign: 'center',
      textTransform: 'lowercase',
    },
  },
}));

const MainMenu: React.FC = () => {
  const listItemStyles = useListItemStyles();

  return (
    <nav>
      {mainMenuItems.map((item, index) => (
        <ListItemLink
          className={listItemStyles.link}
          activeClassName={`${listItemStyles.link}--active`}
          to={item.to}
          key={index}
        >
          <ListItemText
            classes={{ root: listItemStyles.text }}
            primary={item.label}
          />
        </ListItemLink>
      ))}
    </nav>
  );
};

export default MainMenu;

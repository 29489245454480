import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import theme from '../../lib/theme';

import { makeStyles } from '@material-ui/styles';

import uuid from 'react-uuid';

const useLogoStyles = makeStyles({
  root: {
    height: 'auto',
    width: 'auto',
  },
});

interface LogoProps {
  className?: string;
  fill?: string;
}

const Logo: React.FC<LogoProps> = (props) => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const logoStyles = useLogoStyles();

  const clipPathId = uuid();

  return (
    <svg
      className={`${logoStyles.root} ${props.className}`}
      width="287.15"
      height="43"
      viewBox="0 0 287.15 43"
    >
      <title>{data.site.siteMetadata.title}</title>
      <defs>
        <clipPath id={clipPathId}>
          <rect width="287.15" height="43" fill={props.fill} />
        </clipPath>
      </defs>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M56.3,48.513H50.4v-.442c0-4.338.011-8.676-.011-13.014a7.294,7.294,0,0,0-.259-2.01,3.494,3.494,0,0,0-4.086-2.484,3.87,3.87,0,0,0-3.587,3.572,12.411,12.411,0,0,0-.14,1.813c-.012,4.034-.006,8.068-.006,12.1v.456H36.418v-.455q-.006-6.762-.014-13.524a4.6,4.6,0,0,0-.562-2.238,3.621,3.621,0,0,0-3.764-1.734,4.117,4.117,0,0,0-3.7,3.955,13.469,13.469,0,0,0-.077,1.454c-.006,4.034,0,6.544,0,6.7H22.419V25.716h5.855v2.24a7.591,7.591,0,0,1,6.419-2.9,6.82,6.82,0,0,1,6.114,3.194c.572-.551,1.089-1.135,1.691-1.612a7.715,7.715,0,0,1,4.431-1.563,8.726,8.726,0,0,1,5.808,1.491,8.311,8.311,0,0,1,3.329,5.495,13.365,13.365,0,0,1,.224,2.388c.021,4.581.01,9.162.01,13.743Z"
          transform="translate(-16.573 -18.515)"
          fill={props.fill}
        />
        <path
          d="M188.515,48.486h-5.867V45.83c-.431.43-.775.817-1.163,1.153a9.015,9.015,0,0,1-5.176,2.1A11.186,11.186,0,0,1,164.4,39.889a12.628,12.628,0,0,1,2.191-10.47,10.718,10.718,0,0,1,12.377-3.752,8.605,8.605,0,0,1,3.658,2.656V25.676h5.889ZM169.99,36.812a6.681,6.681,0,0,0,1.508,4.6,6.529,6.529,0,0,0,10.022-.523,6.674,6.674,0,0,0,1.047-4.795c-.694-5.435-6.641-6.7-9.993-4.332a6.059,6.059,0,0,0-2.584,5.051"
          transform="translate(-121.302 -18.48)"
          fill={props.fill}
        />
        <path
          d="M429.993,39.5a5.206,5.206,0,0,0,.963,2.023,5.7,5.7,0,0,0,3.707,2.11,7.429,7.429,0,0,0,5-.611,19.829,19.829,0,0,0,2-1.438l4.612,2.659a10.33,10.33,0,0,1-1.922,2.15A11.42,11.42,0,0,1,438.1,49.03a13.989,13.989,0,0,1-7.281-.951,11.044,11.044,0,0,1-6.831-8.794,12.352,12.352,0,0,1,1.593-8.765,11.193,11.193,0,0,1,8.242-5.346,11.639,11.639,0,0,1,7.3,1.077,11.363,11.363,0,0,1,6.026,8.138,13.018,13.018,0,0,1,.11,4.744c-.05.323-.173.378-.464.377q-6.761-.013-13.521-.006h-3.283Zm-.073-4.59h11.572a5.493,5.493,0,0,0-5.613-4.594,5.63,5.63,0,0,0-5.958,4.594"
          transform="translate(-313.289 -18.488)"
          fill={props.fill}
        />
        <path
          d="M740.441,29.862l-4.995,2.724c-.191-.29-.354-.575-.552-.832a4,4,0,0,0-4.668-1.183,2.269,2.269,0,0,0-.579.371,1.426,1.426,0,0,0,.1,2.313,10.584,10.584,0,0,0,2.186.954c1.514.515,3.064.928,4.569,1.467a6.951,6.951,0,0,1,3.007,2.053,6.269,6.269,0,0,1,1.328,5.027,6.583,6.583,0,0,1-4.845,5.671,12.715,12.715,0,0,1-9.39-.3,8.6,8.6,0,0,1-4.476-4.421l5.082-2.86a.653.653,0,0,1,.075.111,4.333,4.333,0,0,0,4.51,2.824,7.679,7.679,0,0,0,1.682-.253,2.243,2.243,0,0,0,.9-.522,1.468,1.468,0,0,0-.113-2.285,6.916,6.916,0,0,0-2.218-.982c-1.512-.487-3.043-.92-4.53-1.472a6.918,6.918,0,0,1-3.414-2.508,6.72,6.72,0,0,1,.51-7.847,7.979,7.979,0,0,1,4.462-2.579,10.975,10.975,0,0,1,7.026.576,9.158,9.158,0,0,1,4.341,3.955"
          transform="translate(-533.84 -18.487)"
          fill={props.fill}
        />
        <path
          d="M328.865,27.494h1.982c1.361,0,2.722.013,4.082-.009a.489.489,0,0,1,.561.4q2.607,7.586,5.235,15.165c.038.111.081.221.149.405.168-.476.314-.884.456-1.294q2.49-7.146,4.973-14.294a.472.472,0,0,1,.537-.382c1.9.016,3.791.008,5.686.008h.4c-.076.2-.133.36-.192.516Q348.56,39,344.394,49.983a.461.461,0,0,1-.52.358q-3.007-.023-6.014,0a.4.4,0,0,1-.445-.305Q333.2,38.91,328.969,27.791c-.03-.078-.055-.158-.1-.3"
          transform="translate(-243.117 -20.318)"
          fill={props.fill}
        />
        <rect
          width="5.846"
          height="29.994"
          transform="translate(137.586)"
          fill={props.fill}
        />
        <path
          d="M284.152,25.7v6.541c-.361,0-.719-.006-1.077,0a6.317,6.317,0,0,0-3.759,1.185,5.182,5.182,0,0,0-2.07,4.342c-.033,3.585-.013,7.17-.015,10.754v.462h-5.88V26.2h5.873v3.7c1.392-2.919,3.812-4.08,6.927-4.193"
          transform="translate(-200.599 -19.001)"
          fill={props.fill}
        />
        <path
          d="M1007.57,25.728h5.872v2.633c.27-.291.475-.523.692-.742a8.645,8.645,0,0,1,5.3-2.476,11.188,11.188,0,0,1,12.231,9.088,12.576,12.576,0,0,1-2.152,10.51,10.8,10.8,0,0,1-12.4,3.817,8.175,8.175,0,0,1-3.375-2.341c-.076-.087-.155-.173-.283-.316v9.781h-5.89Zm12.024,17.841a6.615,6.615,0,0,0,3.887-1.116,6.028,6.028,0,0,0,2.581-4.643A6.66,6.66,0,0,0,1025,33.332a6.55,6.55,0,0,0-9.424-1.151,6.516,6.516,0,0,0-1.782,7.133,5.877,5.877,0,0,0,5.8,4.255"
          transform="translate(-744.856 -18.527)"
          fill={props.fill}
        />
        <path
          d="M925.192,48.509h-5.866V45.854c-.431.43-.775.817-1.162,1.153a9.016,9.016,0,0,1-5.176,2.1,11.186,11.186,0,0,1-11.909-9.194,12.627,12.627,0,0,1,2.191-10.47,10.717,10.717,0,0,1,12.376-3.753,8.605,8.605,0,0,1,3.658,2.656V25.7h5.889ZM906.668,36.828a6.691,6.691,0,0,0,1.511,4.609,6.533,6.533,0,0,0,9.979-.471,6.657,6.657,0,0,0,1.086-4.855c-.692-5.41-6.6-6.69-9.966-4.346a6.045,6.045,0,0,0-2.61,5.062"
          transform="translate(-665.898 -18.497)"
          fill={props.fill}
        />
        <path
          d="M813.653,48.542h-5.869V25.734h5.863v2.458c.345-.383.622-.731.941-1.036a7.888,7.888,0,0,1,5.235-2.068A8.584,8.584,0,0,1,825.78,26.8a8.433,8.433,0,0,1,3.109,5.472,14.428,14.428,0,0,1,.2,2.356c.018,4.52.009,9.04.008,13.561,0,.107-.009.214-.014.343H823.2v-.426c0-4.2.011-8.408-.012-12.613a7.9,7.9,0,0,0-.263-2.011,3.649,3.649,0,0,0-2.8-2.732,5.123,5.123,0,0,0-4.916,1.036,4.855,4.855,0,0,0-1.471,3.167,13.537,13.537,0,0,0-.083,1.635q-.01,5.741,0,11.482Z"
          transform="translate(-597.162 -18.533)"
          fill={props.fill}
        />
        <rect
          width="5.846"
          height="5.845"
          transform="translate(0 24.153)"
          fill={props.fill}
        />
        <rect
          width="5.846"
          height="5.846"
          transform="translate(256.856 37.154)"
          fill={props.fill}
        />
        <path
          d="M595.982,15.746c-.307-.706-.664-1.377-.9-2.089A8.276,8.276,0,0,1,597.43,4.7,8.91,8.91,0,0,1,602.1,2.676a10.318,10.318,0,0,1,6.322,1.018,9.594,9.594,0,0,1,4.448,5.058l-4.841,2.79c-.165-.356-.309-.708-.488-1.041a4.139,4.139,0,0,0-3.032-2.341,3.8,3.8,0,0,0-3,.633,3.093,3.093,0,0,0-.979,3.584,7.138,7.138,0,0,0,1.66,2.578q3.261,3.594,6.544,7.168c.036.039.079.073.146.134a20.511,20.511,0,0,0,1.4-4.744c.127.063.223.106.313.157,1.4.795,2.8,1.595,4.2,2.383a.339.339,0,0,1,.2.459,20.193,20.193,0,0,1-2.29,5.5.346.346,0,0,0,.049.5c1.011,1.1,2.011,2.2,3.015,3.307.154.17.307.341.492.545l-4.533,2.6-2.587-2.784a26.47,26.47,0,0,1-2.48,1.328,12.982,12.982,0,0,1-10.987-.185,7.9,7.9,0,0,1-4.418-8.033,9.388,9.388,0,0,1,4.552-7.412c.056-.035.111-.071.164-.109.017-.012.028-.032.017-.019m3.222,3.7a4.581,4.581,0,0,0-2.25,4,3.538,3.538,0,0,0,2.461,3.549,7.114,7.114,0,0,0,3.178.307,6.82,6.82,0,0,0,2.935-.983L599.2,19.446"
          transform="translate(-437.059 -1.895)"
          fill={props.fill}
        />
      </g>
    </svg>
  );
};

Logo.defaultProps = {
  fill: theme.palette.primary.main,
};

export default Logo;

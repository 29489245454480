import React, { useState } from 'react';

import { ThemeProvider } from '@material-ui/styles';
import theme from '../../lib/theme';

import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/source-code-pro/600.css';
import '@fontsource/source-code-pro/600-italic.css';
import '../../scss/fonts.scss';

import { CssBaseline } from '@material-ui/core';

import ThemeContext from '../../contexts/theme-context';

import Header from './header';
import Footer from './footer';

const Layout: React.FC = ({ children }) => {
  const [appBarVisible, setAppBarVisible] = useState(true);

  const toggleAppBarVisibility = (visible) => {
    setAppBarVisible(visible);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <ThemeContext.Provider
          value={{
            appBarVisible,
            toggleAppBarVisibility,
          }}
        >
          <Header />
          <main>{children}</main>
          <Footer />
        </ThemeContext.Provider>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default Layout;

import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import { Box, Hidden, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const socialListItems = [
  {
    href: 'https://ca.linkedin.com/company/marvelandsnap',
    label: [
      'Li',
      <Typography key="linkedin" variant="srOnly">
        nkedIn
      </Typography>,
    ],
    title: 'Follow us on LinkedIn',
  },
  {
    href: 'https://twitter.com/marvelandsnap',
    label: [
      'Tw',
      <Typography key="twitter" variant="srOnly">
        itter
      </Typography>,
    ],
    title: 'Follow us on Twitter',
  },
];

const useSocialListStyles = makeStyles({
  root: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    textTransform: 'lowercase',
    '& li': {
      display: 'inline-block',
      '&:nth-child(n+2)': {
        marginLeft: '1.5em',
      },
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover, &:focus': {
          color: 'white',
        },
      },
    },
  },
});

const useCopyrightStyles = makeStyles({
  root: {
    display: 'inline-block',
    marginTop: '1em',
  },
});

interface ColophonProps {
  className?: string;
}

const Colophon: React.FC<ColophonProps> = (props) => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const socialListStyles = useSocialListStyles();
  const copyrightStyles = useCopyrightStyles();

  const date = new Date();

  return (
    <Box fontFamily="Source Code Pro" className={props.className}>
      <nav>
        <ul className={socialListStyles.root}>
          {socialListItems.map((item, index) => (
            <li key={index}>
              <a
                href={item.href}
                title={item.title}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography component="span" variant="inherit">
                  {item.label}
                </Typography>
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <small className={copyrightStyles.root}>
        &copy;{date.getFullYear()} {data.site.siteMetadata.title}.{' '}
        <Hidden smUp>
          <br />
        </Hidden>
        All rights reserved.
      </small>
    </Box>
  );
};

export default Colophon;

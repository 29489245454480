import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { PaletteType } from '@material-ui/core';

// Using TypeScript's module augmentation (https://material-ui.com/guides/typescript/#customization-of-theme)
// to add custom properties (in this case, colors for our theme's palette):
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    violet: Palette['primary'];
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    violet: PaletteOptions['primary'];
    neutral: PaletteOptions['primary'];
  }
}

const palette = {
  type: 'dark' as PaletteType,
  primary: {
    main: '#8D81D2',
    dark: '#66619E',
  },
  secondary: {
    main: '#6AD4E2',
    dark: '#247F99',
  },
  error: {
    main: '#F44336',
  },
  warning: {
    main: '#F9C82B',
  },
  info: {
    main: '#6AD4E2',
    dark: '#247F99',
  },
  success: {
    main: '#00CDA0',
  },
  violet: {
    light: '#272538',
    main: '#1C1B28',
    dark: '#15151D',
  },
  neutral: {
    light: '#FFFFFF',
    main: '#F2F2F7',
    dark: '#9998A5',
  },
  grey: {
    400: '#DFDFE1',
    700: '#1C1B28',
  },
};

palette['text'] = {
  primary: palette.neutral.dark,
  secondary: palette.primary.main,
};

palette['background'] = {
  default: palette.violet.dark,
  paper: palette.violet.main,
};

const typography = {
  fontFamily: 'Montserrat, sans-serif',
  fontSize: 18,
  lineHeight: 1.4,
  h1: {
    fontFamily: 'Visby CF, sans-serif',
    fontSize: 100,
    fontWeight: 600,
    color: palette.neutral.light,
    lineHeight: 1,
  },
  h2: {
    fontFamily: 'Source Code Pro, sans-serif',
    fontSize: 50,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h3: {
    fontFamily: 'Source Code Pro, sans-serif',
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h4: {
    fontFamily: 'Source Code Pro, sans-serif',
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h5: {
    fontFamily: 'Source Code Pro, sans-serif',
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h6: {
    fontFamily: 'Source Code Pro, sans-serif',
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  subtitle1: {
    fontSize: 24,
    lineHeight: 1.4,
  },
  subtitle2: {
    fontFamily: 'Visby CF, sans-serif',
    fontWeight: 600,
  },
  button: {
    fontFamily: 'Visby CF, sans-serif',
    fontWeight: 600,
  },
  body1: {
    color: palette.neutral.light,
    fontFamily: 'Visby CF, sans-serif',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.9,
    marginBottom: 20,
  },
};

const overrides = {
  MuiTypography: {
    h1: {
      textTransform: 'lowercase',
    },
    h2: {
      textTransform: 'lowercase',
    },
    h3: {
      textTransform: 'lowercase',
    },
    h4: {
      textTransform: 'lowercase',
    },
    h5: {
      textTransform: 'lowercase',
    },
    h6: {
      textTransform: 'lowercase',
    },
    gutterBottom: {
      marginBottom: '1.6rem',
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
      paddingTop: '1em',
      paddingBottom: '1em',
    },
    label: {
      textTransform: 'lowercase',
      padding: '0.425em',
    },
    outlined: {
      borderWidth: '2px !important',
      borderColor: palette.neutral.light,
      transition: 'background-color 0.2s ease-in',
      '&:hover': {
        backgroundColor: palette.neutral.light,
        color: `${palette.primary.main} !important`,
      },
    },
    outlinedPrimary: {
      borderColor: palette.primary.main,
      color: 'white',
    },
    outlinedSecondary: {
      borderColor: palette.secondary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: palette.secondary.main,
        color: 'white !important',
      },
    },
  },
  MuiContainer: {
    root: {
      ['@media (min-width: 960px)']: {
        paddingLeft: 120,
        paddingRight: 120,
      },
    },
  },
  MuiTextField: {
    root: {
      '& [class*="MuiInputBase-input-"]:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px rgb(250,255,189) inset !important',
        WebkitTextFillColor: '#555555',
      },
    },
  },
};

const props = {
  MuiContainer: {
    maxWidth: false as const,
  },
  MuiPaper: {
    elevation: 0,
    square: true,
  },
};

const theme = responsiveFontSizes(
  createMuiTheme({
    palette,
    typography,
    overrides,
    props,
  }),
  {
    variants: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1'],
  }
);

export default theme;

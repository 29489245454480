import React from 'react';

interface ThemeContextProps {
  appBarVisible: boolean;
  toggleAppBarVisibility: (visible: boolean) => void;
}

const ThemeContext = React.createContext<ThemeContextProps>({
  appBarVisible: true,
  toggleAppBarVisibility() {
    return;
  },
});

export default ThemeContext;

import React, { useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import {
  AppBar,
  Container,
  Grid,
  Slide,
  useMediaQuery,
  useScrollTrigger,
} from '@material-ui/core';
import Logo from '../svg/logo';
import NavigationDrawer from './navigation-drawer';

import theme from '../../lib/theme';
import { makeStyles } from '@material-ui/core/styles';
import ThemeContext from '../../contexts/theme-context';

// Reserving space for fixed <AppBar /> component:
const useHeaderStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.violet.main,
    height: 150,
    [theme.breakpoints.down('sm')]: {
      height: 96,
    },
  },
});

const useAppBarStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.violet.main,
    boxShadow: 'none',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
});

const useLogoStyles = makeStyles({
  root: {
    transform: 'translateY(20%)',
    [theme.breakpoints.only('xs')]: {
      height: 29,
      transform: 'translateY(35%)',
    },
    [theme.breakpoints.only('sm')]: {
      height: 33,
    },
  },
});

const Header: React.FC = () => {
  const { toggleAppBarVisibility } = useContext(ThemeContext);

  const slideTrigger = useScrollTrigger({
    threshold: useMediaQuery(theme.breakpoints.down('sm')) ? 96 : 150,
  });

  useEffect(() => {
    toggleAppBarVisibility(!slideTrigger);
  });

  const headerStyles = useHeaderStyles();
  const appBarStyles = useAppBarStyles();
  const logoStyles = useLogoStyles();

  return (
    <div className={headerStyles.root}>
      <Slide appear={false} direction="down" in={!slideTrigger}>
        <AppBar classes={{ root: appBarStyles.root }}>
          <Container>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={8}>
                <Link to="/">
                  <Logo className={logoStyles.root} />
                </Link>
              </Grid>
              <Grid item>
                <NavigationDrawer logoStyles={logoStyles} />
              </Grid>
            </Grid>
          </Container>
        </AppBar>
      </Slide>
    </div>
  );
};

export default Header;
